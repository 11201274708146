<script>
    import { removeDuplicates } from "@/utils";

    export default {
        name: "ColorList",
        props: {
            typeDirection: {
                default: "row",
                type: String,
            },
            currentOffer: Object,
            offers: Array,
            sizeType: {
                default: "sm",
                type: String,
            },
            isDecorative: Boolean,
        },
        data() {
            return {
                currentColor: this.currentOffer.color?.id,
                // проверка сброса цвета при перходах между товарами
            };
        },
        computed: {
            listColors() {
                return removeDuplicates(
                    this.offers
                        .filter((offer) => offer.color && offer.available)
                        .map((offer) => offer.color)
                );
            },
        },
        methods: {
            selectColor(color) {
                this.currentColor = color.id;
                this.$emit("select-color", color);
            },
            createBgStyle(codeColor) {
                return codeColor === "ffffff"
                    ? `background-color: #${codeColor}; border: 1px solid #bdbdbd;`
                    : `background-color: #${codeColor};`;
            },
        },
    };
</script>

<template>
    <div
        class="c-color-list"
        :class="{
            'c-color-list--row': typeDirection === 'row',
            'c-color-list--column': typeDirection === 'column',
            'c-color-list--lg': sizeType === 'lg',
            'c-color-list--decorative': isDecorative,
        }"
    >
        <button
            class="c-color-list__item outline"
            :class="{
                'c-color-list__item--active': currentColor === color.id && !isDecorative,
            }"
            v-for="color in listColors"
            :key="color.id"
            @click="selectColor(color)"
            :disabled="isDecorative"
            :tabindex="currentColor === color.id && !isDecorative ? -1 : 0"
        >
            <span
                class="c-color-list__item-circle"
                :style="createBgStyle(color.code)"
                role="img"
                :alt="color.name + ' цвет'"
            ></span>
        </button>
    </div>
</template>

<style lang="scss">
    .c-color-list {
        display: flex;
    }

    .c-color-list__item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-right: 2px;
        @include box(16px);
        border: 1px solid transparent;
        border-radius: 50%;
        transition: box-shadow 0.2s ease-in;

        &:hover {
            .c-color-list__item-circle {
                transform: scale(1.05);
            }
        }
    }

    .c-color-list__item-circle {
        @include box(12px);
        border-radius: 50%;
        transition: 0.2s ease all;
    }

    //
    // --mod
    //

    .c-color-list--decorative {
        pointer-events: none;
    }

    .c-color-list--lg {
        .c-color-list__item {
            margin-right: 4px;
            @include box(40px);
            border-radius: 0;
            border-color: var(--primary);
            &:hover,
            &:focus-visible {
                box-shadow: 0 0 0 1px var(--primary) inset;
            }
        }

        .c-color-list__item-circle {
            @include box(20px);
        }

        .c-color-list__item--active {
            border-color: #417abd;
            box-shadow: 0 0 0 1px #417abd inset;
        }
    }

    .c-color-list__item--active {
        pointer-events: none;
        border-color: var(--secondary);

        &:hover {
            .c-color-list__item-circle {
                transform: scale(1);
            }
        }
    }
</style>
