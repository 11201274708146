<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { sendAnalytics } from "@/core/SendAnalytics";

    export default {
        name: "ButtonCompare",
        props: {
            size: String,
            productId: Number,
            isCloneProduct: Boolean,
            isVisibleText: Boolean,
        },
        data() {
            return {
                currentInComparison: false,
                isLoading: false,
            };
        },
        computed: {
            ...mapState("Compare", ["comparisonIdsList"]),
            textForTooltipDesktop() {
                return this.currentInComparison
                    ? "Удалить из сравнения"
                    : "Добавить к сравнению";
            },
            textForTooltipMobile() {
                return this.currentInComparison ? "Уже в сравнении" : "К сравнению";
            },
        },
        watch: {
            comparisonIdsList: {
                handler(updatedIdsList) {
                    this.checkComparisonState(updatedIdsList);
                },
            },
        },
        mounted() {
            this.checkComparisonState(this.comparisonIdsList);
        },
        methods: {
            ...mapActions("Compare", ["addToComparison", "removeFromComparison"]),
            ...mapMutations("Compare", {
                setComparison: "SET_COMPARISON_FOR_CLONE",
            }),
            checkComparisonState(comparisonIdsList) {
                let idsList = [];
                comparisonIdsList.forEach((obj) => {
                    idsList.push(obj.id);
                });
                this.currentInComparison = idsList.includes(this.productId);
            },
            addToComparisonHandler() {
                this.addToComparison(this.productId).then(() => {
                    this.isLoading = false;
                    this.currentInComparison = true;
                    sendAnalytics.send(sendAnalytics.events.compare);
                    if (this.isCloneProduct) {
                        this.setComparison({ type: "add", id: this.productId });
                    }
                });
            },
            removeFromComparisonHandler() {
                this.removeFromComparison(this.productId).then(() => {
                    this.isLoading = false;
                    this.currentInComparison = false;
                    if (this.isCloneProduct) {
                        this.setComparison({ type: "remove", id: this.productId });
                    }
                });
            },
            toggleComparisonHandler() {
                this.isLoading = true;
                if (this.currentInComparison) {
                    this.removeFromComparisonHandler();
                } else {
                    this.addToComparisonHandler();
                }
            },
        },
    };
</script>

<template>
    <button
        class="button-compare outline"
        :class="{
            'is-loading': isLoading,
            'is-comparison': currentInComparison,
            'button-compare--md': size === 'md',
            'button-compare--lg': size === 'lg',
            'button-compare--with-text': isVisibleText,
        }"
        @click="toggleComparisonHandler"
    >
        <span class="button-compare__line button-compare__line--1"></span>
        <span class="button-compare__line button-compare__line--2"></span>
        <span class="button-compare__line button-compare__line--3"></span>
        <span class="button-compare__tooltip button-compare__tooltip--mobile">{{
            textForTooltipMobile
        }}</span>
        <span class="button-compare__tooltip button-compare__tooltip--desktop">{{
            textForTooltipDesktop
        }}</span>
    </button>
</template>

<style lang="scss">
    .button-compare {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        &:not(.is-comparison):hover {
            .button-compare__line {
                background-color: var(--secondary);
            }
        }

        &.is-comparison {
            .button-compare__line {
                background-color: var(--secondary);
            }
        }

        @include bp($bp-mobile-md) {
            &:hover,
            &:focus {
                .button-compare__tooltip {
                    transform: translateX(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .button-compare__tooltip {
        position: absolute;
        right: 34px;
        bottom: 0;
        font-size: 14px;
        white-space: nowrap;
        color: var(--secondary);
        transform: translateX(5px);
        opacity: 0;
        visibility: hidden;
        transition: 0.4s ease-out all;
    }
    .button-compare__tooltip--mobile {
        right: 25px;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
    .button-compare__tooltip--desktop {
        display: none;
        @include bp($bp-desktop-sm) {
            display: block;
        }
    }

    .button-compare__line {
        background-color: #262626;
        transition: 0.3s ease-in-out all;
    }

    .button-compare--md {
        position: relative;

        &:after {
            content: "";
            @include center-absolute(0);
            margin: auto;
            margin: -10px;
        }

        @include box(24px);

        @include bp($bp-desktop-sm) {
            @include box(32px);
        }

        .button-compare__line--1 {
            height: 11px;
        }

        .button-compare__line--2 {
            height: 6px;
        }

        .button-compare__line--3 {
            height: 18px;
        }

        &:not(.is-comparison):hover {
            .button-compare__line--1 {
                height: 18px;
            }

            .button-compare__line--2 {
                height: 8px;
            }

            .button-compare__line--3 {
                height: 12px;
            }
        }

        .button-compare__line {
            width: 2px;
            margin: 0 2px;
        }
    }

    .button-compare--lg {
        border: 1px solid var(--primary-border);
        @include box(58px);
        padding: 14px;

        .button-compare__line {
            width: 3px;
            margin: 0 3px;
        }

        .button-compare__line--1 {
            height: 15px;
        }

        .button-compare__line--2 {
            height: 8px;
        }

        .button-compare__line--3 {
            height: 24px;
        }

        &:not(.is-comparison):hover {
            .button-compare__line--1 {
                height: 24px;
            }

            .button-compare__line--2 {
                height: 10px;
            }

            .button-compare__line--3 {
                height: 15px;
            }
        }

        &.is-comparison {
            background-color: var(--primary);
            border-color: var(--primary);

            .button-compare__line {
                background-color: var(--base-white);
            }
        }

        .button-compare__tooltip {
            display: none;
        }
    }

    .button-compare--with-text {
        @include box(30px);
        padding: 0;
        border: none;
        &.is-comparison {
            border-color: transparent;
            background-color: transparent;
            .button-compare__line {
                background-color: var(--secondary);
            }
        }
        .button-compare__tooltip.button-compare__tooltip--desktop {
            right: auto;
            left: 35px;
            display: block;
            opacity: 1;
            visibility: visible;
            transform: none;
        }
        .button-compare__line {
            background-color: var(--secondary);
        }
    }
</style>
