<script>
    import { mapActions } from "vuex";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import BaseButton from "@main/packages/BaseButton";

    export default {
        name: "ProductCardButtons",
        components: { BaseButton },
        props: {
            isPreOrder: Boolean,
            inCart: Boolean,
            offerId: Number,
            product: Object,
            discountPrice: Number,
        },
        data() {
            return {
                isLoadingBuyButton: false,
                currentInCart: this.inCart,
            };
        },
        computed: {
            textForButton() {
                return this.currentInCart ? "В корзине" : "Купить";
            },
        },
        watch: {
            inCart: {
                handler(updatedInCart) {
                    this.currentInCart = updatedInCart;
                },
            },
        },
        methods: {
            ...mapActions("Root", ["addProductInCart"]),
            add() {
                if (this.currentInCart) {
                    return this.$router.push({ name: "Order" });
                }

                this.isLoadingBuyButton = true;

                this.addProductInCart({
                    quantity: 1,
                    productId: this.offerId,
                }).then(() => {
                    this.isLoadingBuyButton = false;
                    this.currentInCart = true;

                    this.$emit("on-added-to-cart");

                    sendAnalytics.send(sendAnalytics.events.addToCart, {
                        name: this.product.name,
                        offerId: this.offerId,
                        price: this.discountPrice,
                    });
                });
            },
            showPreOrder() {
                this.$popup.show("modal-pre-order", { productId: this.product.id });
            },
        },
    };
</script>

<template>
    <div class="product-card__controls">
        <base-button
            class="product-card__control-button"
            v-if="!isPreOrder"
            variant="v1"
            size="md"
            :is-loading="isLoadingBuyButton"
            @click="add"
        >
            {{ textForButton }}
            <preloader slot="preloader" :show="isLoadingBuyButton" sm-size></preloader>
        </base-button>

        <base-button
            class="product-card__control-button"
            v-if="isPreOrder"
            variant="v1"
            size="md"
            @click="showPreOrder"
        >
            Предзаказ
        </base-button>
    </div>
</template>

<style lang="scss">
    .product-card__controls {
        display: flex;
    }

    .product-card__control-button {
        width: 100%;
    }
</style>
