<script>
    export default {
        name: "FeaturesList",
        props: {
            list: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<template>
    <ul class="features-list">
        <li v-for="(feature, i) in list" :key="i" class="features-list__item">
            {{ feature.name
            }}<template v-if="feature.value.length > 0"
                >: <span class="features-list__item-value">{{ feature.value }}</span>
            </template>
        </li>
    </ul>
</template>

<style lang="scss">
    .features-list {
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .features-list__item {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: var(--primary-text--lighter);

        @include bp($bp-desktop-sm) {
            margin-right: 16px;
        }
    }

    .features-list__item-value {
        color: var(--primary-text);
    }
</style>
