var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card",class:{ 'product-card--in-cart': _vm.inCart, 'product-card--loading': _vm.loading }},[(_vm.product.canCompared)?_c('button-compare',{staticClass:"product-card__compare",attrs:{"size":"md","product-id":_vm.product.id,"is-clone-product":_vm.product.isClone}}):_vm._e(),_c('div',{staticClass:"product-card__img js-lazy-load"},[(_vm.isDiscount)?_c('div',{staticClass:"product-card__sale-label"},[_vm._v(" -"+_vm._s(_vm.discountPercent)+"% ")]):_vm._e(),(_vm.isGift)?_c('div',{staticClass:"product-card__gift-label product-card__gift-label--mobile"},[_vm._v(" + Подарок ")]):_vm._e(),(_vm.isGift)?_c('div',{staticClass:"product-card__gift-img"},[_c('img',{attrs:{"data-src":"/static/xd-design/xd_gift.png","alt":""}})]):_vm._e(),_c('router-link',{staticClass:"product-card__img-link",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug, offer: _vm.offerForParams },
            },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('img',{attrs:{"data-src":_vm.apiUrl(_vm.previewImage),"src":_vm.scrImage,"alt":""}})])],1),_c('router-link',{staticClass:"product-card__header",attrs:{"to":{
            name: 'Product',
            params: { slug: _vm.product.slug, offer: _vm.offerForParams },
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__name",class:{
                'product-card__name--small': _vm.isSmall,
                'product-card__name--gift': _vm.isGift,
            }},[_vm._v(" "+_vm._s(_vm.nameProduct)+" "),(_vm.isGift)?_c('div',{staticClass:"product-card__gift-label product-card__gift-label--desktop"},[_vm._v(" + Подарок ")]):_vm._e()]),_c('div',{staticClass:"product-card__price"},[_c('product-price',{attrs:{"price":_vm.discountPrice,"old-price":_vm.price,"discount-count":_vm.discountPercent,"is-small-size":""}}),(_vm.isGift)?_c('div',{staticClass:"\n                    product-card__gift-label\n                    product-card__gift-label--desktop\n                    product-card__gift-label--desktop-bottom\n                "},[_vm._v(" + Подарок ")]):_vm._e()],1)]),_c('div',{staticClass:"product-card__footer"},[_c('div',{staticClass:"product-card__divider"}),(_vm.offer.color)?_c('div',{staticClass:"product-card__colors"},[_c('color-list',{attrs:{"offers":_vm.currentOffers,"current-offer":_vm.offer},on:{"select-color":_vm.selectColor}})],1):_c('div',{staticClass:"product-card__colors-spy"}),_c('router-link',{staticClass:"product-card__feature",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug, offer: _vm.offerForParams },
            },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('features-list',{attrs:{"list":_vm.product.listSpecifications}})],1),_c('product-card-buttons',{attrs:{"offer-id":_vm.offerId,"product":_vm.product,"is-pre-order":_vm.isPreOrder,"in-cart":_vm.inCart,"discount-price":_vm.discountPrice},on:{"on-added-to-cart":function($event){_vm.inCart = true}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }