<script>
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductCardButtons from "@xd/components/ProductCardButtons";
    import ColorList from "@xd/components/elements/ColorList";
    import FeaturesList from "@xd/components/elements/FeaturesList";
    import ProductPrice from "@xd/components/ProductPrice";
    import ButtonCompare from "@xd/components/ui/ButtonCompare";

    export default {
        name: "ProductCard",
        components: {
            ButtonCompare,
            ProductPrice,
            ProductCardButtons,
            ColorList,
            FeaturesList,
        },
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
            currentColors: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            let colorIndex = currentOffers.findIndex((offer) => {
                return this.currentColors.includes(offer.color?.id.toString());
            });
            if (colorIndex === -1) colorIndex = 0;

            return {
                colorIndex,
                currentOffers,
                offer: currentOffers[colorIndex],
                offerId: currentOffers[colorIndex].id,
                previewImage: currentOffers[colorIndex].previewImage?.medium,
                price: currentOffers[colorIndex].price,
                discountPrice: currentOffers[colorIndex].discountPrice,
                discountPercent: currentOffers[colorIndex].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
                offerForParams:
                    colorIndex === 0 && !this.product.isClone
                        ? undefined
                        : currentOffers[colorIndex].id,
            };
        },
        computed: {
            nameProduct() {
                return this.product.shortName ? this.product.shortName : this.product.name;
            },
            scrImage() {
                return this.previewImageFromOffer
                    ? apiUrl(this.previewImageFromOffer)
                    : require("@/assets/images/preloader_card.gif");
            },
            isDiscount() {
                return this.discountPercent;
            },
            isGift() {
                return this.product.showGiftIcon && !this.isPreOrder;
            },
        },
        methods: {
            apiUrl,

            selectColor(color) {
                let { id, previewImage, price, discountPrice, available } =
                    this.currentOffers.filter((offer) => offer.color.id === color.id)[0];
                this.offerId = id;
                this.previewImageFromOffer = previewImage.medium;
                this.price = price;
                this.discountPrice = discountPrice;
                this.isPreOrder = !available;
                this.inCart = false;
                this.offerForParams = id;
            },

            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <div
        class="product-card"
        :class="{ 'product-card--in-cart': inCart, 'product-card--loading': loading }"
    >
        <button-compare
            class="product-card__compare"
            v-if="product.canCompared"
            size="md"
            :product-id="product.id"
            :is-clone-product="product.isClone"
        ></button-compare>

        <div class="product-card__img js-lazy-load">
            <div v-if="isDiscount" class="product-card__sale-label">
                -{{ discountPercent }}%
            </div>

            <div
                v-if="isGift"
                class="product-card__gift-label product-card__gift-label--mobile"
            >
                + Подарок
            </div>

            <div v-if="isGift" class="product-card__gift-img">
                <img data-src="/static/xd-design/xd_gift.png" alt="" />
            </div>

            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug, offer: offerForParams },
                }"
                class="product-card__img-link"
                tabindex="-1"
            >
                <img :data-src="apiUrl(previewImage)" :src="scrImage" alt="" />
            </router-link>
        </div>

        <router-link
            @click.native.prevent="sendDataLayerEvent"
            :to="{
                name: 'Product',
                params: { slug: product.slug, offer: offerForParams },
            }"
            class="product-card__header"
        >
            <div
                class="product-card__name"
                :class="{
                    'product-card__name--small': isSmall,
                    'product-card__name--gift': isGift,
                }"
            >
                {{ nameProduct }}

                <div
                    v-if="isGift"
                    class="product-card__gift-label product-card__gift-label--desktop"
                >
                    + Подарок
                </div>
            </div>

            <div class="product-card__price">
                <product-price
                    :price="discountPrice"
                    :old-price="price"
                    :discount-count="discountPercent"
                    is-small-size
                ></product-price>
                <div
                    v-if="isGift"
                    class="
                        product-card__gift-label
                        product-card__gift-label--desktop
                        product-card__gift-label--desktop-bottom
                    "
                >
                    + Подарок
                </div>
            </div>
        </router-link>

        <div class="product-card__footer">
            <div class="product-card__divider"></div>

            <div v-if="offer.color" class="product-card__colors">
                <color-list
                    :offers="currentOffers"
                    :current-offer="offer"
                    @select-color="selectColor"
                ></color-list>
            </div>
            <div v-else class="product-card__colors-spy"></div>

            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug, offer: offerForParams },
                }"
                class="product-card__feature"
                tabindex="-1"
            >
                <features-list :list="product.listSpecifications"></features-list>
            </router-link>

            <product-card-buttons
                :offer-id="offerId"
                :product="product"
                :is-pre-order="isPreOrder"
                :in-cart="inCart"
                :discount-price="discountPrice"
                @on-added-to-cart="inCart = true"
            ></product-card-buttons>
        </div>
    </div>
</template>

<style lang="scss">
    .product-card {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        height: auto;

        @include bp($bp-desktop-sm-for-card) {
            &:hover,
            &:focus-within {
                .product-card__name {
                    color: var(--info-text--dark);
                }

                .product-card__gift-img {
                    transform: translateX(0) translateY(0);
                }

                .product-card__img-link {
                    transform: scale(1);
                }
            }
        }
    }

    .product-card__img {
        position: relative;
        z-index: 2;
        overflow: hidden;
    }

    .product-card__sale-label {
        position: absolute;
        @include box(36px);
        font-weight: bold;
        font-size: 12px;
        line-height: 38px;
        border-radius: 50%;
        text-align: center;
        left: 0;
        bottom: 6px;
        background-color: var(--danger);
        color: var(--primary-text--invert);
        z-index: 1;

        @include bp($bp-desktop-sm) {
            @include box(40px);
            font-size: 14px;
            line-height: 42px;
            bottom: 10px;
        }
    }

    .product-card__gift-label {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        background-color: var(--secondary);
        color: var(--primary-text--invert);
        padding: 0 3px;

        @include bp($bp-desktop-sm) {
            top: auto;
            left: auto;
            bottom: -21px;
            right: 0;
            font-size: 14px;
            line-height: 20px;
            color: var(--secondary);
            background-color: transparent;
        }
    }

    .product-card__gift-img {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateX(100%) translateY(100%);
        transition: 0.3s ease-in-out transform;
        z-index: 10;
        pointer-events: none;

        img {
            width: 158px;
            height: 170px;
            object-fit: contain;
        }
    }

    button.product-card__compare {
        margin-left: auto;

        @include bp($bp-desktop-sm) {
            margin-bottom: 10px;

            &:focus-visible {
                outline-offset: -2px;
            }
        }
    }

    .product-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;
        outline-offset: -1px;
        outline: none;
        transform: scale(0.98);
        transition: 0.3s ease-in-out transform;

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            object-fit: cover;
        }
    }

    .product-card__price {
        display: block;
        margin-bottom: 15px;
    }

    .product-card__header {
        margin-top: 10px;
        outline: none;
    }

    .product-card__footer {
        margin-top: auto;
    }

    .product-card__divider {
        border-top: 1px solid var(--neutral--dark);
        margin-bottom: 10px;
    }

    .product-card__name {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: var(--primary-text);
        margin-bottom: 5px;
        padding-right: 10px;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            font-size: 18px;
            padding-right: 20px;
        }
    }

    .product-card__colors {
        padding: 0 2px;
        margin-bottom: 10px;
    }

    .product-card__colors-spy {
        margin-bottom: 10px;
        height: 16px;
    }

    .product-card__feature {
        display: block;
        margin-bottom: 14px;
        outline: none;
    }

    .product-card__controls {
        margin-top: auto;
    }

    .product-card__z-top {
        position: relative;
        z-index: 4;
    }

    //
    // --mod
    //

    .product-card--in-cart {
        .product-card__btn {
            opacity: 1;
            background-color: transparent;
            color: var(--primary-text);

            &:hover {
                background-color: transparent;
            }
        }
    }

    .product-card--loading {
        .product-card__btn {
            opacity: 1;
        }
    }

    .product-card__name--gift {
        @include bp($bp-desktop-sm) {
            padding-right: 77px;
        }
    }

    .product-card__name--small {
        font-size: 13px;
        line-height: 18px;
    }

    .product-card__gift-label--desktop {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            top: 1px;
            bottom: auto;
        }
    }

    .product-card__gift-label--desktop-bottom {
        display: none;
        position: relative;
        margin-top: 5px;
    }
    .product-card__gift-label--desktop-bottom.visible {
        display: inline-block;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .product-card__gift-label--mobile {
        z-index: 8;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
</style>
